<!-- This Vue is similar to the Patient Detail page, but most of the components
    are disabled, and not all of the data needs to be loaded. -->
<template>
    <b-container fluid class="patient-detail d-flex flex-column" v-if="loaded">
        <PatientDetailsHeaderBar />
        <b-row class="py-2">
            <b-col xl="8">
                <b-row class="py-2">
                    <b-col class="py-2">
                        <PersonalCard
                            :patientState="initialState"
                            :doctors="doctors"
                            :newPatient="true"
                        />
                    </b-col>
                </b-row>
                <b-row class="py-2">
                    <!-- The Lens Surgery Info/Reserve/Orders cards -->
                    <b-col class="py-2">
                        <b-card class="tabs-card tab-container py-3 card-disabled">
                            <b-tabs
                                pills
                                horizontal
                                v-model="tabActive"
                                class="d-block lens-tabs"
                                nav-class="d-block d-sm-flex text-center"
                            >
                                <b-tab
                                    v-for="title in tabs"
                                    :key="title"
                                    :title="title"
                                    :disabled="disableTab(title)"
                                    title-link-class="text-white text-caption"
                                    class="mb-4"
                                >
                                    <template #title>
                                        <div class="card-disabled-label">
                                            {{ title }}
                                        </div>
                                    </template>
                                </b-tab>
                                <template>
                                    <LensSelection :disabled="true" />
                                </template>
                            </b-tabs>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" class="py-2">
                <b-row class="py-2">
                    <b-col>
                        <PreOpDataAndCalc disabled="true" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import PatientDetailsHeaderBar from '@/views/patientdetails/components/PatientDetailsHeaderBar.vue';
import LensSelection from '@/views/patientdetails/components/LensSelection.vue';
import PreOpDataAndCalc from '@/views/patientdetails/components/PreOpDataAndCalc.vue';
import PersonalCard from '@/views/patientdetails/components/PersonalCard.vue';
import {mapGetters} from 'vuex';

export default {
    components: {
        PatientDetailsHeaderBar,
        LensSelection,
        PreOpDataAndCalc,
        PersonalCard,
    },
    data() {
        return {
            loaded: false,
            initialState: {},
            name: 'NewPatient',
            show: true,
            info: {},
            tabActive: 0,
        };
    },
    mounted() {
        /* When the page first loads, set the initial state to a default empty state. */
        this.initialState = {
            lastUpdated: '',
            patientId: 0,
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            ocosPatientRef: '',
            gender: '',
            priority: 0,
            patientNote: '',
            updatedByUserId: 0,
            active: true,
            acustomerId: 0,
            doctorId: 0,
            doctorName: '',
            hasPreviousInterventions: null,
        };
    },
    async created() {
        let requests = [];

        /* For New Patient page all the data we need is the list of doctors */
        requests.push(
            this.$store
                .dispatch('doctors/fetchList', {
                    currentPage: 1,
                    perPage: 0, // fetch all the doctors
                })
                .catch((err) => {
                    this.$store.commit('showAlert', {
                        response: err.response,
                        fallbackMsg: 'Failed to fetch doctor data',
                        seconds: 5,
                    });
                })
        );

        this.$store.commit('patient/resetPatient');

        try {
            await Promise.allSettled(requests);
        } finally {
            // All promises are done and info is loaded so we can load the rest of the page and components
            this.loaded = true;
        }
    },
    methods: {
        /* On the new patient page, we only want to be able to view the Personal tab */
        disableTab: function (tabTitle) {
            if (tabTitle != 'Personal') {
                return true;
            }
            return false;
        },
    },
    computed: {
        ...mapGetters({
            doctors: 'doctors/list',
            currentPatientCalculations: 'patient/currentPatientCalculations',
        }),

        tabs: function () {
            return [this.t('lensSurgeryInfo')];
        },
    },
};
</script>

<style>
.tab-title-class {
    color: white;
    text-align: start;
}

.nav-link.active.tab-active-class {
    background-color: 00ccff;
}

.patient-details-card .card-title {
    display: inline;
    margin-right: 5px;
    text-align: start;
}

.patient-details-card .card-subtitle {
    display: inline;
}
</style>
