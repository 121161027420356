<!-- This Vue is for the Lens Selection section of the New Patient and Patient Detail pages. -->
<template>
    <b-card
        class="top-row-section d-relative py-2 light-border"
        :class="{'card-disabled': disabled}"
    >
        <b-row class="mb-3">
            <b-col class="mt-2">
                <h5 class="heavy d-inline mr-1">{{ t('surgeryInfo') }}</h5>
                <span class="text-gray-dark">
                    {{ t('surgeryInfoDetails') }}
                </span>
            </b-col>
        </b-row>
        <template>
            <b-row>
                <b-col lg="6" class="d-flex flex-column patient-detail-lens-selection-col-buffer">
                    <LensSelectionDataItem
                        :lenses="odLenses"
                        :disabled="disabled"
                        opEye="OD"
                        class="mb-2"
                    />
                </b-col>
                <b-col lg="6" class="d-flex flex-column">
                    <LensSelectionDataItem
                        :lenses="osLenses"
                        :disabled="disabled"
                        opEye="OS"
                        class="mb-2"
                    />
                </b-col>
            </b-row>
        </template>
    </b-card>
</template>

<script>
import LensSelectionDataItem from './LensSelectionDataItem.vue';
import {mapGetters} from 'vuex';

export default {
    name: 'LensComponent',

    components: {
        LensSelectionDataItem,
    },

    props: {
        disabled: {
            default: false,
        },
    },

    data() {
        return {};
    },

    computed: {
        ...mapGetters({currentPatientLenses: 'patient/currentPatientLenses'}),

        odLenses() {
            return this.currentPatientLenses.filter((item) => item.opEye === 'OD');
        },

        osLenses() {
            return this.currentPatientLenses.filter((item) => item.opEye === 'OS');
        },
    },

    methods: {},
};
</script>
